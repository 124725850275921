// Components
import StickerOrdersForm from '@/components/elements/stickerOrders/StickerOrdersForm'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapState } from 'vuex'
// Filters
import { formatDate } from '@/filters'
// Utils
import { cloneDeep, get } from 'lodash'

export default {
  name: 'StickerOrdersListItem',
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  filters: { formatDate },
  mixins: [uiMixin],
  computed: {
    ...mapState('stickerOrders', ['stickerOrdersStatus'])
  },
  methods: {
    /**
     * Devuelve la etiqueta del estado del pedido
     *
     * @param {string} value - id del estado del pedido
     * @return {string} - etiqueta del estado del pedido
     */
    getStickerOrdersStatusLabel(value) {
      return get(this.stickerOrdersStatus, `${value}.label`, 'No definido')
    },
    /**
     * Cuando pulsamos sobre el botón de detalle
     *
     * @param {string} id - UID del establecimiento
     */
    handleClickDetail(id) {
      this.modifyAppDialog({
        title: 'Editar pedido de pegatinas',
        contentComponent: cloneDeep(StickerOrdersForm),
        contentComponentProps: { id },
        hideActionButtons: true,
        visible: true
      })
    }
  }
}
